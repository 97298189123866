import Form from '../../../../../components/form';
import request from '../../../../../utils/request';
import PersonList from './person_list.vue';

formCreate.component('PersonList', PersonList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'delegate_form',
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'name') { // 选择费用预算
        v.props = {
          ...v.props,
          params: {
            functionCode: 'process_instance_delegate_table',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const name = this.getRule('name');
      // 费用预算
      name.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            name: `${e[0].positionName}-${e[0].fullName}`,
            delegatePositionCode: e[0].positionCode,
          });
        } else {
          this.setValue({
            name: null,
            delegatePositionCode: null,
          });
        }
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/activiti/runtimeController/delegate';
        const params = {
          delegatePositionCode: formData.delegatePositionCode,
          crmProcessInstanceId: this.formConfig.row[0].crmProcessInstanceId,
          taskId: this.formConfig.row[0].taskId,
          isYb: true,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
